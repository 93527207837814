import type { ComponentPropsWithoutRef, ReactNode } from "react";
import { useEffect, useState } from "react";

import altImage from "~/assets/images/fallback-images/main-image-fallback.png";
import { cn } from "~/utils/classnames";

const ImageComponent = ({
  src,
  ...props
}: ComponentPropsWithoutRef<"img"> &
  ({ fallbackSrc?: string } | { fallbackElement?: ReactNode })) => {
  const [imgState, setImgState] = useState("loading");

  useEffect(() => {
    const img = new Image();
    img.onload = () => setImgState("success");
    img.onerror = () => setImgState("error");
    img.src = src || "";

    return () => {
      img.onload = null;
      img.onerror = null;
    };
  }, [src]);

  const { fallbackSrc, fallbackElement, ...propsRest } = {
    fallbackSrc: undefined,
    fallbackElement: undefined,
    ...props,
  };

  return (
    <>
      {imgState === "loading" ? (
        <div className={cn(propsRest.className, "bg-transparent")}></div>
      ) : null}

      {imgState === "error" ? (
        <>
          {fallbackElement ? (
            fallbackElement
          ) : (
            <img src={fallbackSrc || altImage} alt="" {...propsRest} />
          )}
        </>
      ) : null}

      {imgState === "success" ? <img src={src} alt={props.alt} {...propsRest} /> : null}

      {/* <Transition
        as={Fragment}
        show={imgState === "error"}
        enter="transition-all duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-all duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        {fallbackElement ? (
          fallbackElement
        ) : (
          <img src={fallbackSrc || altImage} alt="" {...propsRest} />
        )}
      </Transition>

      <Transition
        as={Fragment}
        show={imgState === "success"}
        enter="transition-all duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-all duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <img src={src} alt={props.alt} {...propsRest} />
      </Transition> */}
    </>
  );
};

export { ImageComponent as Image };
